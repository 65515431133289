<template>
    <div class="nav_bar font24" style="padding: 0 1.4rem;">
        <div class="item" style="cursor: pointer;">
            <div @click="link('home/index')">首页</div>
        </div>
        <div class="item" v-for="(item,index) in list" :key="index">
           
			<el-dropdown v-for="(item2,index2) in item" :key="item2.id" @command="handleCommand">
			  <div :class="item2.ej.length>0?'menuname haserji':'menuname'" @click="link('secondList', item2)">
				{{item2.name}}
			  </div>			  
			  <el-dropdown-menu v-if="item2.ej.length>0" slot="dropdown">
				<el-dropdown-item v-for="(item3,index3) in item2.ej" :command="{id:item3.id,name:item2.name +'>'+item3.name,url:item3.url}">{{item3.name}}</el-dropdown-item>
			  </el-dropdown-menu>
			</el-dropdown>
		</div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            id: '',
			list:[]
        }
    },
	created() {
		this.$post({
			url: '/api/classify/index',
			params: { 
				pid:0,
				type:1,
				types:2,
				p: 1,
				size: 999
			}
		}).then((res) => {
			this.list = [];
			for (let i = 0; i < res.list.length; i += 2) {
			  this.list.push(res.list.slice(i, i + 2));
			}
		})
	},
    methods: {
		handleCommand(e){
			if(e.url){
				window.open(e.url,'_blank')
				return
			}
			this.$router.push({ name:'secondList', query: { id:e.id,name:e.name } });
		},
        link(name, item) {
			if(name=='home/index'){
				this.$router.push('/')
				return 
			}
			
			if(item.url){
				window.open(item.url,'_blank')
				return
			}
			this.$router.push({ name, query: { id:item.id,name:item.name } });
        }
    }
}
</script>
  
<style scoped lang="scss">
	
.nav_bar {
    background: linear-gradient(180deg, #4F99F2 0%, #2268C3 100%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: .14rem 0;
    margin-bottom: .19rem;
    color: #fff;
	position: relative;
	z-index: 9;
	min-height: 1.14rem;
	width:100vw;
	left: -1rem;
    // .home {
    //     display: flex;
    //     height: 103rem;
    //     justify-content: center;
    //     align-items: center;
    //     margin: ;
    // }

    .item:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border: none;
        padding: 0;

        div {
            padding: 0 .71rem;
            border-right: .02rem solid #fff;
        }
    }

    .menuname:nth-last-child(1) {
        border: none;
    }

    .item {
        padding: 0 .6rem;
		display: flex;
		flex-direction: column;
        align-items: center;
        text-align: center;
        border-right: .02rem solid #fff;
        :nth-child(1) {
            
        }

        .menuname {
            cursor: pointer;
			padding: 5px 0;
			color: #fff;
			font-size: 0.24rem;
			border-bottom: 2px solid rgb(0,0,0,0);
        }
    }
}
.nav_bar .item:nth-last-child(1){
	border-right: none;
}
.haserji:hover{
	border-bottom: 2px solid #fff !important;
}
@media (max-width: 1200px) {
		.nav_bar{
			width:1200px;
			left: -50px;
		}
	}
</style>