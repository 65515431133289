import store from '../../store/index.js';

import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// 提示插件
import { ToastMessage } from './pubilc';

import router from "../../router/index";//路由



axios.defaults.timeout = 30000;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 接口地址
axios.defaults.baseURL = 'http://nxszx.jiankangqh.com';

// 请求拦截器
axios.interceptors.request.use(
	
    config => {		
        //判断vuex里面是否有token 有就携带
  //       const token = store.state.userinfo?.token?store.state.userinfo.token:'';  
		// if(token){
		// 	config.headers.token = token
		// }
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

// 响应拦截器
axios.interceptors.response.use(
	
    response => {
		
        if (response.status === 200) {
            return Promise.resolve(response);
        }else if(response.status === 202){
			
			
			router.push('/login');
		}else{
			// 清除加载(不传值，清除加载样式)
			ToastMessage();
			return Promise.reject(response);
		}
		
    },
    error => {
		ToastMessage();
        return Promise.reject(error.response);
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param url  //api
 * @param params // 参数
 * @param responseType  //相应类型(用于显示图形验证码)
 * @param isToken // 是否判断 登录,并header带上token
 * @param isLoading //是否显示加载  true:显示  false:不显示
 * @param loadingText //加载文案
 * @param isFlg //是否节流
 * @param resolveall//是否返回全部 false:不返回  true:全部返回
 */
let isFlg = false;//节流
export function get(options) {
    // 判断是否显示加载
    if (options.isLoading) {
        ToastMessage({
            message: options.loadingText ? options.loadingText : '',//加载文案
            type: "loading",//类型
            overlay: true,//是否显示遮罩(移动端有效)
            isLoading: true,// pc判断loading字段
        })
    }

    return new Promise((resolve, reject) => {
       axios.get(options.url, {
           params: options.params,
           responseType: options.responseType ? options.responseType : ''
       }).then(res => {				
		   if (options.isLoading) {
		       // 清除加载(不传值，清除加载样式)
		       ToastMessage();
		   }
          
           isFlg = false;
       
           //判断是否返回全部数据
           if (options.resolveall) {
               resolve(res.data);
           } else {
               // 车机 系统接口 返回的是 ok
               if (res.data.code == '1' || res.data.status == "ok" || res.data.status == 1) {//0为成功 
                   resolve(res.data.data);
               } else if(res.data.code == 202){
				   setTimeout(()=>{
					   router.replace('/login')
				   },500)
				   
                   // 提示错误
                   ToastMessage({
                       message:"请登录！",
                   })
               }else{
				   // 提示错误
				   ToastMessage({
				       message: res.data.msg ? res.data.msg : "网络错误，请稍后重试！",
				   })
			   }
           }
       
       }).catch(err => {
           // 清除加载(不传值，清除加载样式)
           ToastMessage();
           isFlg = false;
           if (options.resolveall) {
               reject(err)
           } else {
               // 提示
               ToastMessage({
                   message: "网络错误，请稍后重试！",
                   type: "error"
               })
           }
       })
    })
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param url  //api
 * @param params // 参数
 * @param isToken // 是否判断 登录,并header带上token
 * @param isLoading //是否显示加载  true:显示  false:不显示
 * @param loadingText //加载文案
 * @param isFlg //是否节流
 * @param resolveall//是否返回全部 false:不返回  true:全部返回
 */
export function post(options) {
    // 判断是否显示加载
    if (options.isLoading) {
        ToastMessage({
            message: options.loadingText ? options.loadingText : '',//加载文案
            type: "loading",//类型
            overlay: true,//是否显示遮罩(移动端有效)
            isLoading: true,// pc判断loading字段
        })
    }


    return new Promise((resolve, reject) => {
		const token = store.state.userinfo?.token?store.state.userinfo.token:'';
		options.params.token = token?token:''
        axios.post(options.url, options.params?options.params:{}).then(res => {
        	if(options.isLoading){
        		// 清除加载(不传值，清除加载样式)
        		ToastMessage();
        	}
            
            isFlg = false;
        	
            //判断是否返回全部数据
            if (options.resolveall) {
                resolve(res.data);
            } else {
                //1为成功 ,返回成功
                if (res.data.code == '200' || res.data.code == 200) {
                    resolve(res.data.data);
                } else if(res.data.code == 202){
				   setTimeout(()=>{
				   		router.replace('/login')
				   },500)
                   ToastMessage({
                       message:"请登录！",
                   })
               }else{
                    // 提示错误
                    ToastMessage({
                        message: res.data.msg ? res.data.msg : "网络错误，请稍后重试！",
                    })
                }
            }
        
        }).catch(err => {
            isFlg = false;
            if (options.resolveall) {
                reject(err)
            } else {
                // 提示
                ToastMessage({
                    message: "网络错误，请稍后重试！",
                    type: "error"
                })
            }
        })
    });
}

//导出地址 用于拼接图片src
export let axiosURL = axios.defaults.baseURL