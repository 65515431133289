<template>
	<div class="copyright">
		<div class="select">
			<div class="text font18 color-333 fontweight500">
				网站链接：
			</div>
			<el-select v-model="value1" :placeholder="tips1" filterable @change="openwind(1)">
				<el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<!-- <el-select v-model="value2" :placeholder="tips2" filterable @change="openwind(2)">
				<el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select> -->
			
			<el-select v-model="value4" :placeholder="tips4" filterable @change="openwind(4)" popper-class="selc_up">
				<el-option v-for="item in options4" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<el-select v-model="value3" :placeholder="tips3" filterable @change="openwind(3)">
				<el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<el-select ref="select" placeholder="新媒体矩阵" @visible-change="handleVisibleChange">
			</el-select>
			
			<div class="iconlist" :class="showicons?'showlistbox':''">
				<div v-for="item in iconslist" @click="showcode(item)" style="margin: 0 0.5rem;display: flex;align-items: center;justify-content: center;flex-direction: column;cursor: pointer;">
					<img :src="item.log_url" alt="" style="width: 1rem;height: 1rem;"/>
					<div style="font-size: 0.18rem;margin-top: 0.1rem;max-width: 1rem;text-align: center;">{{item.name}}</div>
				</div>
				
			</div>
		</div>
		<div class="pic">
			<!-- <img src="@/assets/home/bottom_png.png" alt=""> -->
			<div id="_ideConac" style="display: flex;align-items: center;justify-content: center;"></div>
			<div class="text font18 color-333 fontweight500" style="justify-content: center;">
				<p style="font-size: 16px;">您是进入本站的第 {{tongji}} 位浏览者</p>
				<p style="font-size: 16px;margin-top: 5px;">版权所有：中国人民政治协商会议宁夏委员会</p>
				<p style="font-size: 16px;margin-top: 5px;">主办单位： 中国人民政治协商会议宁夏委员会办公室</p>
				<p style="font-size: 16px;margin-top: 5px;cursor: pointer;" @click="openba('https://beian.miit.gov.cn/#/Integrated/index')">备案号：青ICP备20000180号</p>
				<p style="font-size: 16px;margin-top: 5px;cursor: pointer;">技术支持：深圳市科蒂信息技术有限公司</p>
			</div>
		</div>
		
		
		<div class="mycover" v-if="showcodeflag">
			<div style="width: 300px;height: 300px;padding: 20px;background-color: #fff;display: flex;flex-direction: column;align-items: center;justify-content: center;">
				<div style="font-size: 20px;padding-bottom: 10px;border-bottom: 1px solid #eee;width: 100%;text-align: center;">{{showname}}</div>
				<img :src="codeimg" alt="" style="width: 80%;height: 80%;margin-top: 10px;"/>
				<div style="font-size: 14px;margin-top: 5px;color: #666;">扫描二维码</div>
			</div>
			<i @click="showcodeflag=false" class="el-icon-circle-close" style="font-size: 36px;color: #fff;margin-top: 10px;cursor: pointer;"></i>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				value1: '',
				tips1: '',
				options1: [],

				value2: '',
				tips2: '',
				options2: [],

				value3: '',
				tips3: '',
				options3: [],

				value4: '',
				tips4: '',
				options4: [],

				value5: false,
				showicons:false,
				iconslist:[],
				showcodeflag:false,
				showname:'',
				codeimg:"",
				
				tongji:0
			}
		},
		mounted() {
			const script = document.createElement('script');
			    script.src = 'https://dcs.conac.cn/js/29/411/0000/60883164/CA294110000608831640003.js';
			    script.type = 'text/javascript';
			    document.getElementById('_ideConac').appendChild(script);
		},
		created() {
			this.$post({
				url: '/api/login/tongji',
				params: {
					
				}
			}).then((res) => {
				this.tongji = res
			})
			
			
			this.geticons()//获取其他网站
			this.$post({
				url: '/api/adtype/index',
				params: {
					p: 1,
					size: 9999
				}
			}).then((res) => {
				res.list.forEach((item) => {
					if (item.id == 1) {
						this.tips1 = item.name
						this.$post({
							url: '/api/ad/index',
							params: {
								adtype_id: 1,
								p: 1,
								size: 999
							}
						}).then((res) => {
							this.options1 = res.list.reverse()
						})
					}
					if (item.id == 2) {
						this.tips2 = item.name
						this.$post({
							url: '/api/ad/index',
							params: {
								adtype_id: 2,
								p: 1,
								size: 999
							}
						}).then((res) => {
							this.options2 = res.list
						})
					}
					if (item.id == 3) {
						this.tips3 = item.name
						this.$post({
							url: '/api/ad/index',
							params: {
								adtype_id: 3,
								p: 1,
								size: 999
							}
						}).then((res) => {
							this.options3 = res.list.reverse()
						})
					}
					if (item.id == 4) {
						this.tips4 = item.name
						this.$post({
							url: '/api/ad/index',
							params: {
								adtype_id: 4,
								p: 1,
								size: 999
							}
						}).then((res) => {
							this.options4 = res.list.reverse()
						})
					}
				})
			})
		},
		methods: {
			showcode(item){
				this.showname = item.name
				this.codeimg = item.code_url
				this.showcodeflag=true				
			},
			geticons(){
				this.$post({
					url: '/api/otherwebsites/index',
					params: {		
						p: 1,
						size: 999
					}
				}).then((res) => {					
					this.iconslist = res.list
				})
			},
			handleVisibleChange() {
				if (this.value5) {
					this.value5 = false; // 重置标志位
					return;
				}else{
					this.value5 = true; // 设置阻止标志位
					this.$nextTick(() => {
						this.$refs.select.toggleMenu(false); // 手动设置下拉框的显示状态为 false
					});
				}
				this.showicons = !this.showicons
			},
			openba(url){
				window.open(url, '_blank')
			},
			openwind(i) {
				if (i == 1) {
					this.options1.forEach((item, index) => {
						if (item.id == this.value1) {
							window.open(item.tzurl, '_blank')
						}
					})
				}
				if (i == 2) {
					this.options2.forEach((item, index) => {
						if (item.id == this.value2) {
							window.open(item.tzurl, '_blank')
						}
					})
				}
				if (i == 3) {
					this.options3.forEach((item, index) => {
						if (item.id == this.value3) {
							window.open(item.tzurl, '_blank')
						}
					})
				}
				if (i == 4) {
					this.options4.forEach((item, index) => {
						if (item.id == this.value4) {
							window.open(item.tzurl, '_blank')
						}
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	::v-deep #_ideConac a{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mycover{
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,0.4);
		z-index: 100001;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.copyright {
		margin-top: .13rem;
		padding-bottom: .58rem;

		.select {
			padding: .58rem .81rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-items: center;
			display: flex;
			justify-content: space-around;
			align-items: center;
			position: relative;
			z-index: 12;
			.iconlist{
				position: absolute;
				bottom: 1.6rem;
				width: 12rem;
				height: 0;
				overflow: hidden;
				background-color: rgb(245,245,245);
				left: 0;
				right: 0;
				margin: auto;
				z-index: 11;
				transition: all 0.5s; 
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
			.showlistbox{
				height: 3.5rem;
			}
			.showicons{
				
			}
			.el-select {
				width: 3.3rem !important;
				/* 你可以根据需要改变这个值 */
				height: .45rem !important;
			}
			::v-deep .el-input input::placeholder {
			  color: #333;
			}
		}

		.pic {
			display: flex;
			justify-content: center;

			.text {
				margin-left: .3rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
</style>